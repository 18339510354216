import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'PageSections',
    component: () => import('../views/PageSections.vue')
  },
  {
    path: '/fid/:fid',
    name: 'PageThreads',
    component: () => import('../views/PageThreads.vue'),
    props: true
  },
  {
    path: '/cart',
    name: 'PageCart',
    component: () => import('../views/PageCart.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
