<template>
  <mu-text-field v-model="keyword" type="search"
                placeholder="搜索" class="searchbox" @keyup.enter="search">
  </mu-text-field>
</template>

<script>
export default {
  name: 'SearchBar',
  data () {
    return {
      keyword: ''
    }
  },
  methods: {
    search () {
      const fid = Number(this.$route.params.fid) || Number(this.$route.query.fid) || 0
      if (fid === 0) {
        this.$router.push({
          path: '/search',
          query: {
            s: this.keyword
          }
        })
      } else {
        this.$router.push({
          path: '/search',
          query: {
            s: this.keyword,
            fid: fid
          }
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.searchbox
  margin-top 18px
  margin-left 10%
  margin-right 20%
  width 200px
</style>
