import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// Axios
import axios from 'axios'
// MuseUI
import MuseUI from 'muse-ui'
import 'muse-ui/dist/muse-ui.css'
import 'muse-ui-loading/dist/muse-ui-loading.css'
import Loading from 'muse-ui-loading'
import Toast from 'muse-ui-toast'

// Clipboard.js
import VueClipboard from 'vue-clipboard2'

// LazyLoad
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false

// Axios
axios.defaults.withCredentials = true
Vue.prototype.$axios = axios
// MuseUI
Vue.use(MuseUI)
Vue.use(Loading)
Vue.use(Toast)
// Clipboard.js
Vue.use(VueClipboard)
// LazyLoad
Vue.use(VueLazyload, {
  error: '/static/img/default_cover.jpg',
  loading: '/static/img/blank.jpg',
  attempt: 1
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
