import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cartedThreads: []
  },
  mutations: {
    cartOperator (state, thread) {
      var t = state.cartedThreads
      var incart = false
      t.some(item => {
        if (item.tid === thread.tid) {
          incart = true
        }
      })
      if (incart) {
        t.splice(t.findIndex(item => item.tid === thread.tid), 1)
      } else {
        t.push(thread)
      }
    },
    cartReplace (state, threads) {
      state.cartedThreads = threads
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
