<template>
  <div id="app">
    <!-- 全局头部导航栏 -->
    <div id="nav">
      <mu-appbar style="width: 100%;" color="primary">
        <mu-button icon slot="left" @click="drawerOpen = !drawerOpen">
          <mu-icon value="menu"></mu-icon>
        </mu-button>
        <router-link :to="{ path: '/' }" style="color: white">华夏视讯</router-link>
        <SearchBar slot="right" v-if="!isMobile" />
      </mu-appbar>
      <mu-drawer :open="drawerOpen" :docked="false">
        <mu-list>
          <mu-list-item button @click="$router.push({path: '/'}); drawerOpen = false">
            <mu-list-item-title>主页</mu-list-item-title>
          </mu-list-item>
          <mu-list-item button @click="$router.push({path: '/cart'}); drawerOpen = false">
            <mu-list-item-title>购物车</mu-list-item-title>
          </mu-list-item>
          <mu-list-item button @click="startCrawl(); drawerOpen = false">
            <mu-list-item-title>启动远端爬虫</mu-list-item-title>
          </mu-list-item>
          <mu-list-item button @click="latest(); drawerOpen = false">
            <mu-list-item-title>查询最新帖子</mu-list-item-title>
          </mu-list-item>
          <mu-list-item button @click="logout(); drawerOpen = false">
            <mu-list-item-title>注销</mu-list-item-title>
          </mu-list-item>
          <SearchBar />
          <mu-list-item button @click="drawerOpen = false">
            <mu-list-item-title>隐藏侧边栏</mu-list-item-title>
          </mu-list-item>
        </mu-list>
      </mu-drawer>
    </div>
    <!-- 路由 -->
    <router-view/>
  </div>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue'
export default {
  data () {
    return {
      drawerOpen: false,
      searchKeyword: '',
      isMobile: isMobile()
    }
  },
  components: {
    SearchBar
  },
  mounted () {
    this.handleResize = () => {
      this.isMobile = isMobile()
    }
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    startCrawl: function () {
      const url = `${process.env.VUE_APP_API}/crawl/`
      this.$axios
        .get(url)
        .then(response => {
          this.categories = response.data.released
          this.$toast.success('Crawl is now released')
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Failed to release the crawl')
        })
    },
    logout: function () {
      const url = `${process.env.VUE_APP_API}/logout`
      this.$axios
        .get(url)
        .then(response => {
          this.$toast.success('注销成功，3秒后刷新…')
          setTimeout(() => { this.$router.go(0) }, 2000)
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 401) {
            this.$toast.error('未登录')
            this.$router.push({ path: '/login' })
          } else {
            this.$toast.error('注销失败')
          }
        })
    },
    latest: function () {
      const url = `${process.env.VUE_APP_API}/thread/latest/`
      this.$axios
        .get(url)
        .then(response => {
          const date = response.data.date
          this.$toast.success(`最新的帖子：${date}`)
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 401) {
            this.$toast.error('未登录')
            this.$router.push({ path: '/login' })
          } else {
            this.$toast.error('获取信息失败')
          }
        })
    }
  }
}
function isMobile () {
  return window.innerWidth < 660
}
</script>

<style lang="stylus">

</style>
